<script>
import { SuawMainContent } from "@suaw/suaw-component-library";
export default {
  name: "NotFoundPage",
  components: {
    SuawMainContent
  }
};
</script>

<template>
  <SuawMainContent size="large">
    <h1>Oops!</h1>
    <p>
      We can't find the page you're looking for.
      <br />
      <router-link :to="{ name: 'Home' }">Go Home</router-link>
    </p>
  </SuawMainContent>
</template>
